.parent-border {
    background-color: white;
    width: auto;
    height: auto;
    border-color: #000;
    border-width: 1px;
    border-style: solid;
    margin: 10px;
    padding: 10px 0px;
}

.title h4 {
    font-size: 12px !important;
    text-align: center;
}

// hr {
//     height: 0.2px !important;
//     margin: 10px 20px 10px 20px !important;
//     opacity: 1 !important;
// }

.parent-border h4 {
    text-align: center;
    font-size: 12px !important;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.lastPart p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 8px;
}

// .invoice-terms-one {
//     left: 100px;
// }

td {
    height: 0cm;
    font-size: 6px;
}

tfoot {
    float: right;
}

.parent {
    position: relative;
}

.child {
    position: absolute;
}

.invoice-table {
    margin-left: 0.5cm;
    margin-right: 0.5cm;
    width: 94%;
    border-bottom: 1px solid transparent;
}

.parent-invoice-table {
    margin-top: 0.2cm;
    min-height: 50vh;
    // border-bottom: 1px solid #000; 
}

.invoice-terms, .invoice-terms-1 {
    left: 0.5cm;
    bottom: 0cm;
}

.invoice-terms-1 span {
    width: 10%;
    display: inline-block;
    font-weight: bold;
}

// .invoice-terms span {
//     width: 20%;
//     display: inline-block;
//     font-weight: bold;
// }

.invoice-terms-one span {
    width: 40%;
    display: inline-block;
    font-weight: bold;
    text-align: right;
}


