.err {
  color: red;
}


.input-group-text {
  color: #ffffff !important;
  background-color: #26a666 !important;
  border: 1px solid #26a666 !important;
  height: 33px !important;
  cursor: pointer !important;
}
