.parent-invoice-terms {
    height: auto;
}

.parent-invoice-logo-type {
    height: 20px;
}

.parent-invoice-table-address {
    height: 2cm;
}

.column1 {
   width: 60% !important;
}

.column2 {
    width: 40% !important;
    padding: 10px 10px 10px 14px;
    text-align: right;
}

tfoot td {
    padding: 8px 17px;
}

.invoice-table-details tbody {
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
}

.invoice-table-details {
    height: 350px;
}