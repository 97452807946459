
td {
    font-size: 7px;
}

.column1 {
    float: left;
    // width: 45% !important;
    padding: 10px;
}

.column2 {
    float: left;
    // width: 35% !important;
    padding: 10px 10px 10px 14px;

    .invoice-terms-one {
        left: 0px;
        text-align: left;
    }
}

.column3 {
    float: left;
    // width: 20% !important;
    padding: 10px 10px 10px 14px;

    .borderLine {
        text-align: center;

        .number {
            border: 1px solid #000;
            padding: 0px 4px;
            // display: inline-block;
        }
    }
    
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.lastPart {
    margin-left: 10px !important;
}

.lastPart p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 8px;
}

// .invoice-terms-one {
//     left: 100px;
// }

.boxy {
    height: 80px;
    display: block;
    border: 1px solid #000;
}

table.invoice-table-address td.text-right {
    padding-left: 2px;

}

.table-row-border>th {
    font-size: 7px !important;
    font-weight: bold;
    color: #000;
    line-height: 8px;
    background-color: #fff;
}

.invoice-table-details {
    border: 1px solid #000;
    display: block;
    height: auto !important;
    margin: 10px;
}

.invoice-table-details .MainValue {
    display: block !important;
    min-height: 330px;
    overflow: auto !important;
    border-bottom: 1px solid #000;
}

.invoice-table-details tbody {
    display: block !important;
    min-height: auto;
    overflow: auto !important;
    border-bottom: 1px solid #fff;
    border-top: 1px solid transparent;
}

.invoice-table-details thead {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.invoice-table-details tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.invoice-table-details tbody tr td {
    padding: 2px 4px !important;
    font-size: 8px;
}

// .invoice-table-details {
//     width: 19.6cm;
//     margin-left: 0.5cm;
// }

.invoice-table-details p {
    margin-bottom: 0px;
}

td {
    height: .10cm;
    font-size: 9px;
}

.table-addresses>th {
    color: #000;
    width: 4cm;
    text-align: left;
    background-color: #fff;
}

tfoot {
    float: right;
}

tfoot td {
    padding: 12px 17px;
}

table.invoice-table-address {
    margin-left: 0.5cm;
}

table.invoice-table-address td {
    font-size: 8px;
    text-align: left;
    height: 0.5cm;
    padding: 4px;
    vertical-align: top;
    line-height: 10px;
}

.parent {
    position: relative;
}

.child {
    position: absolute;
}

.invoice-table {
    margin-left: 0.5cm;
    margin-right: 0.5cm;
}

.parent-invoice-logo-type {
    height: 1cm;
    /* border: #000 1px solid; */
}

.parent-invoice-table-address {
    margin-top: 0.1cm;
    height: 3cm;
    /* border: #000 1px solid; */
}

.parent-invoice-table {
    margin-top: 0cm;
    /* border: #000 1px solid; */
}

.parent-invoice-total {
    margin-top: 1cm;
    /* border: #000 1px solid; */
    height: 1cm;
}

.invoice-type {
    font-size: 16px;
    color: #000;
    left: 0.5cm;
    bottom: 0cm;
    font-weight: 600;
}

.invoice-logo {
    right: 0.5cm;
    bottom: 0cm;
}

.invoice-total-text {
    font-size: 30px;
    font-weight: 700;
    color: #000;
    left: 0.5cm;
    bottom: 0cm;
}

.invoice-total {
    right: 0.5cm;
    bottom: 0cm;
    font-size: 30px;
    font-weight: 700;
}

.invoice-terms {
    left: 0.5cm;
    bottom: 0cm;
}

.invoice-terms span {
    width: 20%;
    display: inline-block;
    font-weight: bold;
}

.invoice-terms-one span {
    width: 40%;
    display: inline-block;
    font-weight: bold;
    text-align: right;
}

.invoice-terms-one p {
    margin: 0px;
    font-size: 9px;
    text-align: left;
}

.rupeeBox .boxxo {
    font-size: 6px;
    border: 1px solid #000;
    padding: 2px 10px 2px 6px;
    display: inline-block;
    margin-top: 6px;
}

.rupeeBox {
    font-size: 9px;
    font-weight: bold;
}

.rupeeBox p.signatureSp {
    padding-bottom: 40px;
}

.sp {
    padding-left: 14px;
    display: inline-block;
}

.invoice-terms p {
    margin: 0px;
    font-size: 8px;
    line-height: 8px;
}

.childOne {
    position: relative;
}

.invoice-table-address {
    width: 95%;
}

.qrImg {
    width: 50px;
    margin: 5px;
}



// .MobileViewInvoice {


//     body {
//         margin: 0px;
//         padding: 0px;
//         font-size: 11px;
//         color: #000;
//     }

//     div {
//         margin: 0px;
//         padding: 0px;
//     }

//     .bgimg {
//         display: inline-block;
//         padding: 80px 0px 0px 15px;
//         font-family: sans-serif, Arial, Helvetica;
//         font-size: 11px;
//         color: #000;
//     }

//     #tblDetl td,
//     #tblDetlP2 td,
//     #tblDetlP3 td,
//     #tblDetlP4 td,
//     #tblDetlP5 td,
//     #tblDetlP6 td,
//     #tblDetlCN td {
//         padding: 4px 3px;
//         vertical-align: top;
//     }

//     #tblDetl span,
//     #tblDetlP2 span,
//     #tblDetlP3 span,
//     #tblDetlP4 span,
//     #tblDetlP5 span,
//     #tblDetlP6 span,
//     #tblDetlCN span {
//         font-size: 11px;
//         line-height: 11px;
//         color: #000;
//         display: block;
//     }

//     .lblRs {
//         width: 55px;
//         display: inline-block;
//         float: left;
//     }

//     .amtWord {
//         width: 240px;
//         display: inline-block;
//     }

//     .headerClass {
//         line-height: 13px;
//         outline: 1px solid #000;
//     }

//     .smallText {
//         font-weight: bold;
//     }

//     .paddLeft {
//         padding-left: 77px;
//         display: block;
//     }

//     /* .crdLbl {
//         font-size: 10px;
//         line-height: 13px;
//     } */

//     .pagebreakhere {
//         page-break-before: always;
//     }

//     #tblPrint,
//     #tblPrint1,
//     #tblPrint2,
//     #tblPrint4,
//     #tblPrint5,
//     #tblPrint6 {
//         position: absolute;
//         left: 0px;
//         top: 91.5%;
//         border-top: 1px solid #000;
//         line-height: 14px;
//         width: 100%;
//         padding-top: 1px;
//     }

//     #tblPrint {
//         top: 74.5%;
//     }

//     .rightF {
//         float: right;
//     }

//     .borderTop {
//         border-top: 1px solid #000;
//     }

//     .crdPadd {
//         padding-right: 50px;
//     }

//     .txtRgt {
//         text-align: right;
//     }

//     .refrAnx {
//         margin: 0px;
//         line-height: 12px;
//         text-align: center;
//     }

//     .cnLbl {
//         margin: 0px;
//         line-height: 13px;
//         margin-left: 25px;
//     }

//     .cnTdWidth {
//         width: 110px;
//     }

//     .grayTD {
//         background: #aaa;
//         width: 50px;
//     }

//     /*.headerClass{ text-align:center}*/
//     .auto-style1 {
//         height: 14px;
//     }

//     .bottom_mar {
//         margin-bottom: -3px;
//     }
// }