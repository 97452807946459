.parent-border {
    background-color: white;
    width: auto;
    height: auto;
    border-color: #000;
    border-width: 1px;
    border-style: solid;
    margin: 10px;
}

.title h4 {
    font-size: 12px !important;
    text-align: center;
}

.parent-invoice-terms-one {
    width: 200px;
    margin-bottom: 10px;
}

.parent-border h4 {
    text-align: center;
    font-size: 12px !important;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.lastPart {
    margin-left: 10px !important;
}

.lastPart p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 8px;
}

.boxy {
    height: 80px;
    display: block;
    border: 1px solid #000;
}

table.invoice-table-address td.text-right {
    padding-left: 2px;

}

.table-row-border>th {
    font-size: 7px !important;
    font-weight: bold;
    color: #000;
    line-height: 8px;
    background-color: #fff;
}

.invoice-table-details {
    border: 1px solid #000;
    display: block;
    height: 372px;
    margin: 10px;
}

.invoice-table-details tbody {
    display: block !important;
    height: 350px !important;
    overflow: auto !important;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
}

.invoice-table-details thead {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.invoice-table-details tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.invoice-table-details tbody tr td {
    padding: 2px 4px !important;
    font-size: 8px;
}


.invoice-table-details p {
    margin-bottom: 0px;
}

td {
    height: 0cm;
    font-size: 7px;
}

.table-addresses>th {
    color: #000;
    width: 4cm;
    text-align: left;
    background-color: #fff;
}

tfoot {
    float: right;
}

tfoot td {
    padding: 12px 25px;
}

table.invoice-table-address {
    margin-left: 0.5cm;
}

table.invoice-table-address td {
    font-size: 8px;
    text-align: left;
    height: 0.5cm;
    padding: 4px;
    vertical-align: top;
    line-height: 10px;
}

.parent {
    position: relative;
}

.child {
    position: absolute;
}

.invoice-table {
    margin-left: 0.5cm;
    margin-right: 0.5cm;
    width: 94%;
    border-bottom: 1px solid #000;
}

.parent-invoice-logo-type {
    height: 1cm;
    margin-bottom: 10px;
    /* border: #000 1px solid; */
}

.parent-invoice-table-address {
    margin-top: 0.1cm;
    height: 3cm;
    /* border: #000 1px solid; */
}

.parent-invoice-table {
    margin-top: 0.2cm;
    /* border: #000 1px solid; */
}

.parent-invoice-total {
    margin-top: 1cm;
    /* border: #000 1px solid; */
    height: 1cm;
}


.invoice-type {
    font-size: 12px;
    color: #000;
    left: 0.5cm;
    bottom: 0cm;
    font-weight: 600;
}

.invoice-logo {
    right: 0.5cm;
    bottom: 0cm;
}

.invoice-total-text {
    font-size: 30px;
    font-weight: 700;
    color: #000;
    left: 0.5cm;
    bottom: 0cm;
}

.invoice-total {
    right: 0.5cm;
    bottom: 0cm;
    font-size: 30px;
    font-weight: 700;
}

.invoice-terms, .invoice-terms-1 {
    left: 0.5cm;
    bottom: 0cm;
}

.invoice-terms-1 span {
    width: 10%;
    display: inline-block;
    font-weight: bold;
}

.invoice-terms span {
    width: 20%;
    display: inline-block;
    font-weight: bold;
}

.invoice-terms-one span {
    width: 40%;
    display: inline-block;
    font-weight: bold;
    text-align: right;
}

.invoice-terms-one p {
    margin: 0px;
    font-size: 7px;
    line-height: 10px;
    text-align: left;
}

.rupeeBox .boxxo {
    font-size: 5px;
    border: 1px solid #000;
    padding: 2px;
    display: block;
    width: 70%;
}

.border-bottom-black{
    border-bottom: 1px solid #000;
}

// .column {
//     float: left;
//     width: 50% !important;
//     padding: 10px;
// }

.rupeeBox {
    font-size: 7px;
    font-weight: bold;
}

.rupeeBox p.signatureSp {
    padding-bottom: 20px;
}

.sp {
    padding-left: 14px;
    display: inline-block;
}

.invoice-terms p {
    margin: 0px;
    font-size: 8px;
    line-height: 8px;
}

.invoice-terms-1 p {
    margin: 0px;
    font-size: 7px;
    line-height: 14px;
}

.parent-invoice-terms-6 .blockSpan {
    width: 75%;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
}

.childOne {
    position: relative;
}

.invoice-table-address {
    width: 95%;
}

.qrImg {
    width: 50px;
    margin: 5px;
}